.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.mainMenuContainer
{
  margin-top: 30px;
}

.facebook
{
  color:white;
  transition: 0.3s;
}

.facebook:hover
{
  color:#4267B2;
  cursor:pointer;
}

.paddedH1
{
  margin-bottom: 30px;
}

.pictures
{
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: #282c34;
  padding-top: 30px;
  padding-bottom: 30px;

}

.footer
{
  width: 100%;
  height: 300px;
  background-color:#282c34;
  margin-top: 50px;
}

.textCenter
{
  text-align: center;
}
.dark-overlay
{
  position: absolute;
  left:0;
  top: 0;
  width: 100%;
  height: 500px;
  background-color: rgba(0,0,0,0.5);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.navLink
{
  color:#282c34;
  margin-right: 5px;
  margin-left: 5px;
  text-decoration:none;
  transition: 0.5s;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
}



.pictures
{
  border-radius: 10px;
}

.hoverIcon:hover
{
  cursor:pointer;
}

.page {
  position:absolute;
  width: 100vw;
}

.page2{
  position:absolute;
  background: linear-gradient(-45deg, #FF7900, #FF9211);
  width: 100vw;
}

.page2-appear { opacity: 0;
  transform: translateX(-100vw);
}

.page-appear { opacity: 0;
  transform: translateY(100vh);
}

.page2-appear-active {
  opacity: 1;
  transform: translateX(0vh);

  transition: opacity 300ms, transform 500ms;
}

.page-appear-active {
  opacity: 1;
  transform: translateY(0vh);

  transition: opacity 300ms, transform 500ms;
}

.page2-enter {
  opacity: 0;
  transform: translateX(100vw);
}

.page-enter {
  opacity: 0;
  transform: translateY(100vh);
}

.page2-enter-active {
  opacity: 1;
  transform: translateY(0vh);

  transition: opacity 300ms, transform 500ms;
}

.page-enter-active {
  opacity: 1;
  transform: translateY(0vh);

  transition: opacity 300ms, transform 500ms;
}

.tabs
{
  transition: 0.5s;
}

.imgHolder
{
  width: 100%;
  height: 250px;
  overflow: hidden;
  margin-bottom: 30px;
  position:fixed;
  z-index: -1;
}

.whiteBackgroundContainer
{
  background-color: #f1f1f1;
}

body
{
  background-color: #f1f1f1;
  color: #3f3f3f;
}

.priceIndicator
{
  margin-right: 10px;
}

.najamButton
{
  margin-bottom: 20px;
}

.margined
{
  margin-bottom:15px;
}

.footer
{
  color: #f1f1f1;
  padding-top: 30px;
}
.daImage
{
  margin-bottom: 30px;
  margin-top:30px;
  height:250px;
}

.daImage2
{
  height: 50px;
}

.animatedContainer
{
  transition: 0.5s;

}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page2-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: translateY(100vh);
  transition: opacity 300ms, transform 500ms;
}

.page2-exit-active {
  opacity: 0;
  transform: translateX(-100vh);
  transition: opacity 300ms, transform 500ms;
}

.thanksContainer
{
  margin-top: 40px;
  color: #F2f2f2;
}

.viewPort
{
  pointer-events:none;
  position:fixed;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
}

.background-store{
  min-height: 100vh;
}

.activeNavLink
{
  color: black;
  border-left: solid 1px;
  border-right: solid 1px;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.mainStoreContainer
{
  margin-top: 100px;
  background: white;
}

.loadingCardImageContainer
{
  
  overflow-x: hidden;
  padding-left: 0px;
  padding-right: 0px;
  background: grey;
  width: 100%;
  height: 200px;
  text-align: center;

}

.centerSpinner
{
  margin : 80px;
}

.card{
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
cursor: pointer;
}

.card:hover{
   transform: scale(1.05);
box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}


.defaultCard
{
  margin: 20px;
}

.no-more-articles {
  padding-top: 100px;
  padding-bottom: 100px;
}

h5
{
  margin-top: 30px;
  margin-bottom: 10px;
}

.searchButton
{
  margin-top: 30px;
}

.right
{
  text-align: right;
}

.tutorial
{
  font-style:italic;
  padding: 30px;
  background-color: white;
  border-radius: 10px;
  margin-top: 50px;
}

.spacedButton
{
  margin-left: 20px;
  margin-right: 20px;
}

.main-link
{
  display: inline-block;
  margin-right: 10px;
  transition: 0.3s;
}

.alatnicaNum
{
  padding: 5px;
  background: #FF7900;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

.main-link p
{
  display: inline-block;
}

.background-store
{
  background: linear-gradient(-45deg, #FF7900, #FF9211);
}

.overridenButton:hover
{
  background-color: #FF9211;
}
.overridenButton
{
 background-color: #FF7900;
 border-color: #FF9211;
}

.calendarHeader
{
  margin-top: 10px;
}

.overridenButton:focus
{
  background-color:#FF9211;
  border-color:#FF7900;;
}

a
{
  color: inherit;
}

.orangeNav
{
  color:#FF9211;
}

.fixedHeightCol
{
  height: 400px;
}

.titusCross:hover
{
  cursor: pointer;
  color: red;
}

.titusCross
{
  transition: 0.3s;
}

.alatnicaCard:hover
{
  transform:scale(1.05);
}

.fixedHeightContainer
{
  overflow-y:scroll;
  overflow-x: hidden;
  height: 320px;
}

.searchDisplay
{
  margin-top: 60px;
  color: #F2f2f2;
}
.section
{
  margin-top: 10px;
}
.section::-webkit-scrollbar {
  width: 16px;
}

.section::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.section::-webkit-scrollbar-thumb {
  background-color: #FF7900;
  border-radius: 100px;
}

.alatnicaCard
{
  transition: 0.3s;
  box-shadow: -2px 19px 20px 0px #FF9211;
  color: white;
  background-color: #FF7900;
  border-radius: 5px;
  margin: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 10px;
}
.main-link:hover
{
  color: #FF7900;
  cursor: pointer;
}
.sideButtons
{
  flex-direction: right;
}
.containedImage
{
  height: 100%;
}